const IMAGES = {
  // LOGO
  logo: require("../img/logo/darklogo.png"),
  lightlogo: require("../img/logo/lightlogo.png"),
  goldlogo: require("../img/logo/goldlogo.png"),
  coin: require("../img/logo/tbcoin.png"),
  //HOME
  banner1: require("../img/home/banner1.jpeg"),
  banner2: require("../img/home/banner2.jpeg"),
  banner3: require("../img/home/banner3.jpeg"),
  banner4: require("../img/home/banner4.jpeg"),
  banner5: require("../img/home/banner5.jpeg"),
  banner6: require("../img/home/banner6.jpeg"),
  banner7: require("../img/home/banner7.jpeg"),
  banner8: require("../img/home/banner8.jpeg"),
  banner9: require("../img/home/banner9.jpeg"),
  //
  phonepe: require("../img/products/phonepe.png"),
  paytm: require("../img/home/paytm.webp"),
  gpay: require("../img/home/gpay.webp"),
  phonepe: require("../img/home/phonepe.webp"),
  upi: require("../img/home/upi.webp"),
  // contact
  support: require("../img/support.png"),
  support2: require("../img/support2.png"),
  // leaderboard
  first: require("../img/leaderboard/one.png"),
  second: require("../img/leaderboard/two.png"),
  third: require("../img/leaderboard/three.png"),
};

export default IMAGES;
