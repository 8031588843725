import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HomeIcon from "@mui/icons-material/Home";
import GamepadIcon from "@mui/icons-material/Gamepad";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpIcon from "@mui/icons-material/Help";
import LightModeIcon from "@mui/icons-material/LightMode";
import IMAGES from "../../img/image";
import "./SideMenu.css";
import "./SwichButton.css";
import { setMode } from "../../redux/features/colorModeSlice";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const { mode } = useSelector((state) => state.mode);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [submenu, setSubmenu] = useState(false);
  const [toggle, setToggle] = useState(true);

  const handleLogout = () => {
    localStorage.clear();
    message.success("Logout Successful");
    navigate("/login");
  };
  return (
    <div
      className={`sidemenu-container d-block d-md-block d-lg-none ${
        sideMenu ? "active" : ""
      }`}
    >
      <div className="sidemenu">
        <div className="iconandimg">
          <HighlightOffIcon
            onClick={() => setSideMenu(!sideMenu)}
            className="close-icon"
          />
          <div className="logo">
            <img src={mode ? IMAGES.logo : IMAGES.lightlogo} alt="" />
          </div>
          <hr className={`${mode ? "text-white" : "text-dark"} mb-4`} />
        </div>

        <ul className="p-0">
          <li
            className={`${location.pathname === "/" && "active"}`}
            onClick={() => setSideMenu(!sideMenu)}
          >
            <HomeIcon className="icon" />
            <Link to="/">Home</Link>
          </li>
          {user && (
            <li
              className={`${
                location.pathname === "/user-dashboard" && "active"
              }`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <DashboardIcon className="icon" />
              <Link to="/user-dashboard">Dashboard</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/my-account" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <AccountCircleIcon className="icon" />
              <Link to="/my-account">Account</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/orders" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <LocalMallIcon className="icon" />
              <Link to="/orders">Orders</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/query" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <HelpIcon className="icon" />
              <Link to="/query">Queries</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/wallet" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <AccountBalanceWalletIcon className="icon" />
              <Link to="/wallet">Wallet</Link>
            </li>
          )}
          <li
            className={`${location.pathname === "/support" && "active"}`}
            onClick={() => setSideMenu(!sideMenu)}
          >
            <SupportAgentIcon className="icon" />
            <Link onClick={() => setSideMenu(!sideMenu)} to="/support">
              Support
            </Link>
          </li>
          <li>
            <LightModeIcon className="icon" />
            <Link className="d-flex align-items-center">
              Dark Mode
              <div
                className={`togglebutton`}
                onClick={() => {
                  const newMode = !mode;
                  setToggle(newMode);
                  dispatch(setMode(!mode));
                  localStorage.setItem("theme", newMode ? "true" : "false");
                }}
              >
                <div className={`circlee ${toggle && "active"}`}></div>
              </div>
              {/* <input
                  onClick={() => {
                    const newMode = !mode;
                    dispatch(setMode(!mode));
                    localStorage.setItem("theme", newMode ? "true" : "false");
                  }}
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  checked={mode}
                /> */}
            </Link>
          </li>
          <hr className={`${mode ? "text-white" : "text-dark"} mb-4`} />
          {!user && (
            <li
              className={`${location.pathname === "/login" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <LoginIcon className="icon" />
              <Link onClick={() => setSideMenu(!sideMenu)} to="/login">
                Login
              </Link>
            </li>
          )}
          {user && (
            <li
              onClick={() => {
                setSideMenu(!sideMenu);
                handleLogout();
              }}
            >
              <LogoutIcon className="icon" />
              <Link>Logout</Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
