import { configureStore } from "@reduxjs/toolkit";
import { alertSlice } from "./features/alertSlice";
import { userSlice } from "./features/userSlice";
import { discountSlice } from "./features/discountSlice";
import { querySlice } from "./features/querySlice";
import { colorMode } from "./features/colorModeSlice.js";

export default configureStore({
  reducer: {
    alerts: alertSlice.reducer,
    user: userSlice.reducer,
    discount: discountSlice.reducer,
    query: querySlice.reducer,
    mode: colorMode.reducer,
  },
});
