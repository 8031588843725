import { createSlice } from "@reduxjs/toolkit";

export const colorMode = createSlice({
  name: "mode",
  initialState: {
    mode: false,
  },
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { setMode } = colorMode.actions;
